<template>
  <div class="score-wrapper">
    <div class="header">
      <p v-if="theme" class="title">
        {{ theme }}
      </p>
      <p v-if="themeIntro" class="intro">
        {{ themeIntro }}
      </p>
    </div>
    <div class="content">
      <div class="input-container">
        <el-form ref="formData" :model="formData" class="form-body">
          <div v-for="(item, index) in queryDataList" :key="index" class="input-wrapper">
            <el-form-item :rules="item.rules" :prop="'queryData.' + index">
              <el-input
                v-model="formData.queryData[index]"
                :placeholder="item.title"
                @focus="item.active = true"
                @blur="item.active = false"
              >
                <i slot="prefix" class="el-input__icon el-icon-search" />
                <template v-if="formData.queryData[index] && item.active" slot="suffix">
                  <div class="clear-btn" @click="clearInput(index)" />
                </template>
              </el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div>
        <div ref="checkboxWrapper" class="checkbox-wrapper">
          <el-checkbox v-model="formData.subStatus" class="el-checkbox-round">
            <div v-html="$t('page.scoreQuery.legalCheck')" />
          </el-checkbox>
        </div>
        <div class="button-wrapper">
          <template v-if="formData.queryData[0] || formData.queryData[1] || formData.queryData[2]">
            <el-button
              class="button common-button-normal"
              :loading="loading"
              @click="submit"
            >
              {{ queryWhereConfig.btnTitle }}
            </el-button>
          </template>
          <template v-else>
            <div class="forbid-btn">
              {{ queryWhereConfig.btnTitle }}
            </div>
          </template>
        </div>
      </div>
      <div v-if="showContent" class="search-result-content">
        <div class="result-left">
          <p class="text">
            {{ $t('page.scoreQuery.queryResult') }}
          </p>
          <div v-for="(item, index) in queryResultConfig.list" :key="index" class="student-info">
            <div class="label">
              {{ item.title }}
            </div>
            <div class="desc">
              <span v-if="item.type === 1">{{ resultData.studentName }}</span>
              <span v-if="item.type === 2">{{ resultData.score }}</span>
              <span v-if="item.type === 3">{{ resultData.scoreRank }}</span>
              <span v-if="item.type === 4">{{ resultData.awardType }}</span>
            </div>
          </div>
          <template v-if="shareConfig.choose === 1">
            <div class="line-with-text">
              <span class="line-text">{{ $t('page.scoreQuery.share') }}</span>
            </div>
            <div class="share-option">
              <div
                v-if="shareConfig.channel.includes(first)"
                class="share-btn download"
                @click="handleDownload"
              />
              <div
                v-if="shareConfig.channel.includes(second)"
                class="share-btn facebook"
                @click="shareToFacebook"
              />
              <div
                v-if="shareConfig.channel.includes(third)"
                class="share-btn ins"
                @click="handleDownload"
              />
              <div
                v-if="shareConfig.channel.includes(fourth)"
                class="share-btn whatsapp"
                @click="handleDownload"
              />
            </div>
          </template>
          <!-- 海报 -->
          <div ref="poster" class="poster">
            <template v-if="shareConfig.show.includes(first)">
              <div class="name">
                {{ resultData.studentName }}
              </div>
            </template>
            <template v-if="shareConfig.show.includes(third)">
              <div class="cup">
                <svg xmlns="http://www.w3.org/2000/svg" width="870" overflow="visible">
                  <text
                    x="0"
                    y="0"
                    alignment-baseline="text-before-edge"
                    text-anchor="start"
                  >
                    {{ extractText(resultData.awardType) }}
                  </text>
                </svg>
              </div>
            </template>
            <template v-if="shareConfig.show.includes(second)">
              <div class="rank">
                {{ resultData.scoreRank }}
              </div>
            </template>
            <img :data-not-lazy="true" v-lazy-load :data-src="shareConfig.tempUrl" alt="">
          </div>
        </div>
        <div
          v-if="certConfig.choose === 1"
          ref="certBody"
          class="cert-body"
        >
          <template v-if="certInfo.show.includes(first)">
            <div class="name">
              {{ resultData.studentName }}
            </div>
          </template>
          <template v-if="certInfo.show.includes(second)">
            <div class="school">
              {{ resultData.school }}
            </div>
          </template>
          <div v-if="isShowBtn" ref="operation" class="operation" data-html2canvas-ignore="true">
            <el-button class="button down-btn" :loading="certLoading" @click="certDownload">
              <div class="btn-group">
                <div class="icon" />
                <div class="text">
                  {{ certConfig.btnTitle }}
                </div>
              </div>
            </el-button>
          </div>
          <img :data-not-lazy="true" v-lazy-load :data-src="certInfo.tempUrl" alt="" @load="isShowBtn = true">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from './mixin';
import html2canvas from 'html2canvas';
import { oneApi } from 'config/api'

export default {
  mixins: [mixin],
  data() {
    return {
      lottieBox: null,
      play: false,
      webPosterUrl: '',
      isShowBtn: false,
    };
  },
  methods: {
    submit() {
      this.$refs.formData.validate((valid) => {
        if (!valid) {
          return false;
        }
        if (!this.formData.subStatus) {
          // this.$ElMessage.error('请先阅读并同意相关条款');
          const animationBody = this.$refs.checkboxWrapper;
          animationBody.classList.add('shake');
          setTimeout(() => {
            animationBody.classList.remove('shake');
          }, 500);
          return false;
        }
        this.handleSearch();
        return true
      });
    },
    async handleSearch() {
      this.touchPosterUrl = '';
      this.loading = true;
      const params = {
        filter: {
          matchingCondition1: this.formData.queryData[0],
          matchingCondition2: this.formData.queryData[1],
          matchingCondition3: this.formData.queryData[2],
        },
      };
      const res = await this.$axios.post(oneApi.getScoreQuery, params, { rewritePostBody: true })
      this.showContent = false
      if (res.code === 11004) {
        this.$ElMessage.error(res.msg);
        this.loading = false;
        return
      }
      if (res.data) {
        this.resultData = res.data;
        this.certInfo = this.certConfig.list.find((item) => item.award === res.data.awardType) || {};
        this.isShowBtn = false;
        if (Object.keys(this.certInfo).length === 0) {
          this.$ElMessage.error(this.$t('page.scoreQuery.notFoundTip'));
          this.loading = false;
          return
        }
        this.showContent = true;
        this.$nextTick(async () => {
          await this.getPoster();
        });
      } else {
        this.$ElMessage.error(this.$t('page.scoreQuery.notFoundTip'));
      }
      this.loading = false;
    },
    async handleDownload() {
      const link = document.createElement('a');
      link.href = this.base64PosterUrl;
      link.download = 'poster.png';
      link.click();
      this.$ElMessage.success('Download successful');
    },
    clearInput(index) {
      this.formData.queryData[index] = '';
    },
    async shareToFacebook() {
      try {
        if (!this.checkSharePoster()) {
          return
        }
        const shareWindow = window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.webPosterUrl)}`, '_blank');
        console.log(shareWindow, 'shareWindow');
        // const timer = setInterval(() => {
        //   if (shareWindow.closed) {
        //     clearInterval(timer);
        //     this.$message.success('分享成功');
        //   }
        // }, 1000);
      } catch (error) {
        console.error('分享失败:', error);
      }
    },
    async getPoster() {
      if (!this.checkSharePoster()) {
        return
      }
      const canvas = await html2canvas(this.$refs.poster, {
        scale: 1,
        useCORS: true,
        width: this.$refs.poster.offsetWidth,
        height: this.$refs.poster.offsetHeight,
        logging: true,
      });

      // 将 canvas 转换为图片 URL
      const base64Img = canvas.toDataURL('image/png');
      this.base64PosterUrl = base64Img;

      const filePaths = base64Img.split(';')[0];
      const base64WithoutHeader = base64Img.split(',')[1];
      const binaryString = atob(base64WithoutHeader);
      const binaryData = new Uint8Array(binaryString.length);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < binaryString.length; i++) {
        binaryData[i] = binaryString.charCodeAt(i);
      }
      const { buffer } = binaryData;

      const url = await this.uploadToS3(buffer, filePaths)
      this.webPosterUrl = url;
    },
  },
};
</script>
<style lang="scss" scoped>
.score-wrapper {
  width: 1240px;
  height: 100%;
  margin: 0 auto;

  .header {
    text-align: center;

    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 24px;
      font-weight: bold;
      color: #222;
      margin-bottom: 20px;
      line-height: 30px;
    }

    .intro {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #222;
      line-height: 20px;
    }
  }

  .content {
    width: 970px;
    margin: auto;
  }

  @keyframes shake {
    0%,
    100% {
      transform: translateX(0);
    }

    20% {
      transform: translateX(-8px);
    }

    40% {
      transform: translateX(8px);
    }

    60% {
      transform: translateX(-6px);
    }

    80% {
      transform: translateX(6px);
    }
  }

  /deep/.checkbox-wrapper {
    display: flex;
    justify-content: flex-start;

    &.shake {
      animation: shake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97);
    }

    .el-checkbox__label {
      font-family: 'Montserrat-Regular', 'Montserrat', sans-serif;
      font-weight: 400;
      color: #7a7a7a;
      line-height: 24px;
      font-size: 16px;
    }

    .link-text {
      color: var(--txt-color-link);
      text-decoration: underline;
    }
  }

  .button-wrapper {
    margin-top: 20px;
    text-align: center;

    .common-button-normal {
      height: 48px;
      display: inline-flex;
      line-height: 48px;
      width: 300px;
      border-radius: 70px;
    }
    .forbid-btn {
      display: inline-block;
      width: 300px;
      height: 48px;
      background: var(--txt-color-link);
      opacity: 0.6;
      border-radius: 70px;
      font-size: 16px;
      font-family: 'Montserrat-Bold', 'Montserrat', sans-serif;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 48px;
      cursor: not-allowed;
    }
  }
}

::v-deep .el-input__inner {
  height: 62px;
  border-radius: 16px;
  font-size: 16px;
  font-family: 'PingFangSC-Medium', 'PingFang SC', sans-serif;
  font-weight: 500;
  color: #222;
  line-height: 24px;
  padding-left: 48px;
}

::v-deep .el-form-item__content {
  width: 100%;
}

::v-deep .el-input__suffix {
  right: 20px;
  top: 21px;
}

.input-container {
  width: 100%;
  margin: 40px auto 10px;

  .form-body {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .input-wrapper {
      flex: 1;

      .clear-btn {
        width: 20px;
        height: 20px;
        background: url("~assets/images/share/clear-icon.png") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
    }

    .form-body > .input-wrapper:nth-child(1) {
      flex: 1 1 100%;
    }

    .form-body > .input-wrapper:nth-child(2),
    .form-body > .input-wrapper:nth-child(3) {
      flex: 1 1 calc((100% - 20px) / 2);
    }

    .form-body > .input-wrapper:nth-child(3) {
      flex: 1 1 calc((100% - 40px) / 3);
    }
  }

  .el-input__icon {
    font-size: 20px;
    margin-left: 10px;
  }

  .search-btn {
    width: 130px;
    height: 60px;
    cursor: pointer;
    line-height: 60px;
  }
}

.search-result-content {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 60px;

  .result-left {
    width: 420px;

    .text {
      font-size: 24px;
      font-family: 'PingFangSC-Semibold', 'PingFang SC', sans-serif;
      font-weight: 600;
      color: #222;
      line-height: 26px;
      margin-bottom: 30px;
    }

    .student-info {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;
      font-size: 18px;
      font-family: 'PingFangSC-Medium', 'PingFang SC', sans-serif;
      font-weight: 500;
      color: #222;
      line-height: 24px;

      .label {
        white-space: nowrap;
        margin-right: 20px;
        opacity: 0.6;
      }

      .desc {
        width: 100%;
        height: 62px;
        border-radius: 16px;
        border: 1px solid rgba(23, 43, 77, 0.1);
        padding: 19px 20px;
        text-align: left;
      }
    }

    .line-with-text {
      display: flex;
      align-items: center;
      text-align: center;
      font-size: 16px;
      font-family: 'PingFangSC-Medium', 'PingFang SC', sans-serif;
      color: rgba(34, 34, 34, 0.6);
      line-height: 18px;
      width: 100%;
      margin: 60px 0 40px 0;

      &::before,
      &::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid rgba(23, 43, 77, 0.1);
      }

      &::before {
        margin-right: 15px;
      }

      &::after {
        margin-left: 15px;
      }
    }

    .line-text {
      white-space: nowrap;
    }

    .share-option {
      display: flex;
      // padding: 0 35px;
      justify-content: space-around;

      .share-btn {
        width: 50px;
        height: 50px;
        cursor: pointer;
      }

      .download {
        background: url("~assets/images/share/icon_download_web.png") no-repeat;
        background-size: 100%;
      }

      .facebook {
        background: url("~assets/images/share/icon_facebook_web.png") no-repeat;
        background-size: 100%;
      }

      .ins {
        background: url("~assets/images/share/icon_ins_web.png") no-repeat;
        background-size: 100%;
      }

      .whatsapp {
        background: url("~assets/images/share/icon_whatsapp2_web.png") no-repeat;
        background-size: 100%;
      }
    }

    .poster {
      position: absolute;
      top: -9999px;
      left: -9999px;
      width: 1000px;
      height: 1000px;
      display: flex;
      justify-content: center;
      align-items: center;

      .name,
      .cup,
      .rank {
        position: absolute;
      }

      .name {
        top: 159px;
        font-size: 65px;
        left: 67px;
        font-weight: bold;
        color: #fff;
      }

      .cup {
        // -webkit-text-stroke: 10px transparent;
        // color: #FFFBAC;
        // background: #00A488;
        // -webkit-background-clip: text;
        font-weight: bold;
        stroke: #00a488;
        stroke-width: 20px;
        paint-order: stroke;
        stroke-linejoin: round;
        font-size: 115px;
        left: 60px;
        top: 360px;
        fill: #fffbac;
      }

      .rank {
        bottom: 215px;
        left: 68px;
        font-size: 90px;
        font-weight: bold;
        color: #fff;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .cert-body {
    position: relative;
    width: 400px;
    height: 574px;
    display: flex;
    justify-content: center;
    align-items: center;

    .name,
    .school {
      position: absolute;
    }

    .name {
      top: 260px;
      font-size: 30px;
      font-family: 'Playball', sans-serif;
    }

    .school {
      top: 311px;
      font-family: 'Baskvill', sans-serif;
      font-size: 19px;
    }

    .operation {
      position: absolute;
      bottom: 48px;

      .down-btn {
        width: 180px;
        height: 44px;
        box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        font-size: 18px;
        font-family: 'PingFangSC-Semibold', 'PingFang SC', sans-serif;

        .btn-group {
          display: flex;
          align-items: center;

          .icon {
            width: 13px;
            height: 44px;
            background: url("~assets/images/share/icon-down.png") no-repeat;
            background-size: 100% 100%;
            margin-right: 2px;
          }

          .text {
            line-height: 21px;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}

.el-form-item {
  display: flex;
  align-items: center;
  // justify-content: center;
}
</style>
