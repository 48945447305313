export default {
  props: {
    // 组件名称
    componentName: {
      default: '',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 栏目标题
    title: {
      default: '',
      type: String,
    },
    // 栏目介绍
    describe: {
      default: '',
      type: String,
    },
    // 按钮名称
    buttonName: {
      default: '',
      type: String,
    },
    // 按钮链接
    buttonUrl: {
      default: '',
      type: String,
    },
    // 内容项列表
    dataList: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      curIndex: 0,
    }
  },
  methods: {
    handleToPlay() {
      this.$refs.videoPopup.handleOpen(this.dataList[this.curIndex].videoUrl)
      this.cardTrackReport()
    },
    changeSwiperIndex(idx) {
      this.curIndex = idx
    },
  },
}
